import React, { useState } from "react";
import { AuthIncLogo, AuthImg, PostingImg, TrackingHologramImg, TamperProofImg } from "../assets/images";
import { getHologramInfo } from "../clients/AuthIncClient";
import { Hologram } from "../types/Hologram";
import { useHistory } from "react-router-dom";
import RouteConstants from "../constants/RouteConstants";
import { Box, BoxProps, Button, Grid, GridProps, styled, TextField, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import BackgroundBox from "../components/common/BackgroundBox";
import ReCAPTCHA from "react-google-recaptcha";

const VIDEO_URL = "http://cloudfront.authenticatorsinc.com/AI.mp4";

const Auth = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const CustomTextField = styled(TextField)(({ theme }) => ({
  flexDirection: "column-reverse",
  width: 300,
  marginBottom: theme.spacing(2),
  "& .MuiFilledInput-input": { padding: theme.spacing(2) },
  "& .MuiFormHelperText-root": {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold"
  },
  "& input:placeholder-shown": { fontStyle: "italic" },
}));

const FeaturetteBox = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
}));

interface FeaturetteProps extends BoxProps {
  gray?: boolean;
}

const Featurette = styled(Grid)<FeaturetteProps>((props) => ({
  display: "flex",
  background: props.gray ? props.theme.palette.gray.main : "none",
  padding: `${props.theme.spacing(3)} ${props.theme.spacing(5)}`,
  borderBottom: `1px solid ${props.theme.palette.primary.main}`,
}));

interface FeaturetteImgBoxProps extends GridProps {
  imgRight?: boolean;
}

const FeaturetteImgBox = styled(Grid)<FeaturetteImgBoxProps>((props) => ({
  textAlign: "center",
  margin: `${props.theme.spacing(1)} 0`,
  order: props.imgRight ? 2 : 0,
  [props.theme.breakpoints.down("md")]: {
    order: 0,
  },
}));

const FeaturetteImg = styled("img")({
  borderRadius: "50%",
  width: 200,
});

const FeaturetteText = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const FeaturetteTextHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const FeaturetteTextBody = styled(Typography)({
  textAlign: "center",
});

const Home: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [videoOpen, setVideoOpen] = useState(false);

  const [validRecaptcha, setValidRecaptcha] = useState(false);

  const [hologramId, setHologramId] = useState("");
  const [lookupError, setLookupError] = useState(false);

  const getHologram = async () => {
    try {
      setLoading(true);
      const hologram: Hologram = await getHologramInfo(hologramId);
      history.push(RouteConstants.DETAILS, { hologram });
    } catch (e) {
      console.error(e);
      setLookupError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <BackgroundBox bgImgType="headline" marginBottom={4}>
        {videoOpen ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: 743, display: "flex", justifyContent: "end" }}>
              <Button variant="text" onClick={() => setVideoOpen(false)}>
                Close
              </Button>
            </Box>
            <video src={VIDEO_URL} controls autoPlay style={{ width: 743 }} />
          </Box>
        ) : (
          <Box>
            <Typography variant="h3" color="white" mb={2}>
              Authenticators, Inc.
            </Typography>
            <Typography variant="body2" color="primary" sx={{ fontWeight: "lighter", mb: 2 }}>
              See how Authenticators, Inc. verifies genuine memorabilia
            </Typography>
            <Button
              variant="contained"
              endIcon={<ArrowRightIcon />}
              onClick={() => setVideoOpen(true)}
              sx={{
                "& .MuiButton-endIcon > :nth-of-type(1)": {
                  fontSize: 32,
                },
                width: 170,
              }}
            >
              Watch Now
            </Button>
          </Box>
        )}
      </BackgroundBox>
      <Auth mb={4}>
        <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
          Enter your hologram information to find your item in our authentication database
        </Typography>
        <CustomTextField
          value={hologramId}
          onChange={(event) => setHologramId(event.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              getHologram();
            }
          }}
          variant="filled"
          placeholder="example: cv574890"
          error={lookupError}
          helperText="Invalid Hologram ID"
        />
        <Box mb={2}>
          <ReCAPTCHA
            sitekey="6LdAba0UAAAAAG8ioXrV4tEIDgbzaFzWxxvGlSUi"
            onChange={() => setValidRecaptcha(true)}
            onExpired={() => setValidRecaptcha(false)}
          />
        </Box>
        <Button
          onClick={getHologram}
          variant="contained"
          disabled={loading || !validRecaptcha}
          sx={{ "&:hover": { backgroundColor: "secondary.main" }, width: 300 }}
        >
          {loading ? "Searching..." : "Authenticate"}
        </Button>
      </Auth>
      <FeaturetteBox>
        <Featurette container gray>
          <FeaturetteImgBox item lg={3} md={4} xs={12}>
            <FeaturetteImg src={AuthIncLogo} alt="Authenticators Inc. logo" />
          </FeaturetteImgBox>
          <FeaturetteText item lg={9} md={8} xs={12}>
            <FeaturetteTextHeader variant="h4">Background</FeaturetteTextHeader>
            <FeaturetteTextBody variant="body1">
              Since its launch in 2006, Authenticators, Inc. (A.I) has become the industry standard for autographed and
              game-used memorabilia authentication. Designed to distinguish officially authenticated memorabilia from
              other items on the market, the program offers an objective third-party authentication system that
              guarantees genuine memorabilia for collectors all over the world.
            </FeaturetteTextBody>
          </FeaturetteText>
        </Featurette>
        <Featurette container>
          <FeaturetteImgBox item lg={3} md={4} xs={12} imgRight>
            <FeaturetteImg src={AuthImg} alt="Authenticating item" />
          </FeaturetteImgBox>
          <FeaturetteText item lg={9} md={8} xs={12}>
            <FeaturetteTextHeader variant="h4">Authentication</FeaturetteTextHeader>
            <FeaturetteTextBody variant="body1">
              Off–duty law enforcement officers working as independent contractors are assigned by Authenticators, Inc.
              to authenticate autographs and event-used memorabilia. These authenticators must directly witness all
              items being signed and/or used in an event in order to authenticate them.
            </FeaturetteTextBody>
          </FeaturetteText>
        </Featurette>
        <Featurette container gray>
          <FeaturetteImgBox lg={3} item md={4} xs={12}>
            <FeaturetteImg src={PostingImg} alt="Putting hologram on the bottom of a base" />
          </FeaturetteImgBox>
          <FeaturetteText lg={9} item md={8} xs={12}>
            <FeaturetteTextHeader variant="h4">Tamper-Proof Holograms</FeaturetteTextHeader>
            <FeaturetteTextBody variant="body1">
              Once witnessed, items receive a tamper-proof hologram created by OpSec, U.S. with a unique alpha-numeric
              combination to easily identify its authenticity. Collectors can easily distinguish officially
              authenticated memorabilia from other items by locating this official hologram.
            </FeaturetteTextBody>
          </FeaturetteText>
        </Featurette>
        <Featurette container>
          <FeaturetteImgBox item lg={3} md={4} xs={12} imgRight>
            <FeaturetteImg src={TrackingHologramImg} alt="Hologram sticker" />
          </FeaturetteImgBox>
          <FeaturetteText lg={9} item md={8} xs={12}>
            <FeaturetteTextHeader variant="h4">Tracking the Items</FeaturetteTextHeader>
            <FeaturetteTextBody variant="body1">
              Once collectors receive their authenticated piece of memorabilia, they can trace the unique serial number
              on the Authentication database located on authenticatorsinc.com. This convenient reference database
              enables collectors to easily identify all of the details surrounding their new item. Since its inception
              in 2006, Authenticators, Inc. has authenticated over 6 million items, allowing fans to ensure their
              memorabilia is 100% genuine.
            </FeaturetteTextBody>
          </FeaturetteText>
        </Featurette>
        <Featurette container gray>
          <FeaturetteImgBox lg={3} item md={4} xs={12}>
            <FeaturetteImg src={TamperProofImg} alt="Tamper-proof hologram" />
          </FeaturetteImgBox>
          <FeaturetteText lg={9} item md={8} xs={12}>
            <FeaturetteTextHeader variant="h4">Security</FeaturetteTextHeader>
            <FeaturetteTextBody variant="body1">
              In order to ensure the security of authenticated items, holograms are designed to show traces of tampering
              if a hologram is removed. This image shows holograms that have been removed from items and are no longer
              considered valid.
            </FeaturetteTextBody>
          </FeaturetteText>
        </Featurette>
      </FeaturetteBox>
    </Box>
  );
};

export default Home;
