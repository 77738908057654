import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, CardMedia, Grid, GridProps, styled, Typography } from "@mui/material";
import RouteConstants from "../constants/RouteConstants";
import { Hologram as HologramDetails } from "../types/Hologram";
import { AuthIncLogo } from "../assets/images";

const DetailsRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: `1px solid ${theme.palette.borderPrimary.main}`,
  padding: theme.spacing(4),
  background: "#f2f3f4",
}));

const DetailsGridRoot = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const GridRow = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.borderSecondary.main}`,
}));

interface GridRowItemProps extends GridProps {
  isGridRowVal?: boolean;
}

const GridRowItem = styled(Grid)<GridRowItemProps>((props) => ({
  padding: props.theme.spacing(1),
  borderLeft: props.isGridRowVal ? `1px solid ${props.theme.palette.borderSecondary.main}` : "none",
}));

const BoldTypography = styled(Typography)({
  fontWeight: "bold",
});

type LocationState = {
  hologram: HologramDetails;
};

const HologramDetails: React.FC = () => {
  const { state } = useLocation<LocationState>();
  const hologram: HologramDetails = state ? state.hologram : null;

  const history = useHistory();

  const returnToHome = () => {
    history.push(RouteConstants.BASE);
  };

  const printPage = () => {
    window.print();
  }

  return hologram ? (
    <Box mb={2} p={3}>
      <Box className="no-print" mb={2}>
        <Button variant="contained" onClick={returnToHome} sx={{ mr: 1 }}>
          Back to Hologram Lookup
        </Button>
        <Button variant="contained" onClick={printPage}>
          Print
        </Button>
      </Box>
      <DetailsRoot>
        <DetailsGridRoot container md={8}>
          <Typography variant="h3" color="primary" mb={4}>
            Hologram number: <span style={{ fontWeight: "bold" }}>{hologram.hologramId}</span>
          </Typography>
          {hologram.licenseeLogoBase64 && (
            <CardMedia
              component="img"
              image={`data:image/png;base64,${hologram.licenseeLogoBase64}`}
              sx={{ width: 140, mb: 4 }}
            />
          )}
          <Grid sx={{ background: "#fff" }} container mb={2}>
            <GridRow container>
              <GridRowItem item xs={3}>
                <BoldTypography variant="body1">Event</BoldTypography>
              </GridRowItem>
              <GridRowItem item xs={9} isGridRowVal>
                <Typography>{hologram.sessionName}</Typography>
              </GridRowItem>
            </GridRow>
            <GridRow container>
              <GridRowItem item xs={3}>
                <BoldTypography variant="body1">Date</BoldTypography>
              </GridRowItem>
              <GridRowItem item xs={9} isGridRowVal>
                <Typography>{hologram.sessionDate}</Typography>
              </GridRowItem>
            </GridRow>
            <GridRow container>
              <GridRowItem item xs={3}>
                <BoldTypography variant="body1">Autographer</BoldTypography>
              </GridRowItem>
              <GridRowItem item xs={9} isGridRowVal>
                <Typography>{hologram.autographer}</Typography>
              </GridRowItem>
            </GridRow>
            <GridRow container>
              <GridRowItem item xs={3}>
                <BoldTypography variant="body1">Item</BoldTypography>
              </GridRowItem>
              <GridRowItem item xs={9} isGridRowVal>
                <Typography>{hologram.itemName}</Typography>
              </GridRowItem>
            </GridRow>
            {hologram.description && (
              <GridRow container>
                <GridRowItem item xs={3}>
                  <BoldTypography variant="body1">Additional Info</BoldTypography>
                </GridRowItem>
                <GridRowItem item xs={9} isGridRowVal>
                  <Typography>{hologram.description}</Typography>
                </GridRowItem>
              </GridRow>
            )}
          </Grid>
          <Typography variant="body1" className="no-print" sx={{ textAlign: "center", mb: 3 }}>
            If you are experiencing any problems with your authentication, please contact{" "}
            <a href="mailto:info@authenticatorsinc.com">info@authenticatorsinc.com</a>
          </Typography>
          <Box mb={-8}>
            <Typography sx={{ fontWeight: 200, fontSize: 12 }}>Powered By</Typography>
            <CardMedia component="img" image={AuthIncLogo} sx={{ width: 70 }} />
          </Box>
        </DetailsGridRoot>
      </DetailsRoot>
    </Box>
  ) : (
    <Box></Box>
  );
};

export default HologramDetails;