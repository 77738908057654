import React from "react";
import { Box, styled, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BackgroundBox from "../components/common/BackgroundBox";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters {...props} />)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary  sx={{ fontSize: 18 }} expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "1.2rem" }} />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  fontWeight: "bold",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.secondary.main,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails sx={{ marginLeft: "27px" }} {...props} />
))({});

const FAQ: React.FC = () => {
  return (
    <Box mb={2}>
      <BackgroundBox bgImgType="headline" marginBottom={0}>
        <Typography variant="h3" color="white">Frequently Asked Questions</Typography>
      </BackgroundBox>
      <Box>
        <Accordion>
          <AccordionSummary>
            I have a piece of memorabilia and I'd like to find out its value. Can Authenticators, Inc. help?
          </AccordionSummary>
          <AccordionDetails>We do not appraise or attach values to any memorabilia.</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>How can I get my personal memorabilia authenticated?</AccordionSummary>
          <AccordionDetails>
            Unfortunately, we do not authenticate past autographs or game-used items. Currently, our system only
            authenticates items that are directly witnessed by a designated Authenticator at the moment it is signed or
            used and only in conjunction with a Licensee.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            I have a hologram, but it is not in the system or is listed incorrectly. What should I do?
          </AccordionSummary>
          <AccordionDetails>
            If you have an item which does not appear in the system or is listed incorrectly, please e-mail an image of
            the hologram and a brief description and image of your item to{" "}
            <a href="mailto:info@authenticatorsinc.com">info@authenticatorsinc.com</a>. Once we receive the image we
            will investigate the matter and get back to you as soon as possible.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>I have an item that is listed as Team Issued. What does this mean?</AccordionSummary>
          <AccordionDetails>
            Team Issued is a designation by an independent third party authenticator who attests that the item was
            issued by the Licensee. This designation is not intended to determine use of the item in any capacity.{" "}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default FAQ;
