import { ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const spacingValue = 8;

const spacing = (value: number) => value * spacingValue;

const AUTH_INC_GOLD = "#ba9767";
const AUTH_INC_RED = "#c30626";
const GRAY = "#dce1e7";
const WHITE = "#fff";
const BLACK = "#000";

export const Theme: ThemeOptions = {
  spacing: spacingValue,
  typography: {
    fontFamily: '"Roboto Slab", serif',
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
    h3: {
      fontSize: 40,
    },
    h4: {
      fontSize: 28,
    },
    h5: {
      fontSize: 20,
    }
  },
  palette: {
    primary: {
      main: AUTH_INC_GOLD,
      contrastText: BLACK,
    },
    secondary: {
      main: AUTH_INC_RED,
      contrastText: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: WHITE,
    },
    borderPrimary: {
      main: AUTH_INC_GOLD,
    },
    borderSecondary: {
      main: GRAY,
    },
    gray: {
      main: GRAY,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: spacing(1),
          fontSize: 16,
          fontWeight: "lighter",
          textTransform: "none",
        },
        iconSizeMedium: 32,
        contained: {
          backgroundColor: AUTH_INC_RED,
          color: WHITE,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: BLACK,
        },
      },
    },
  },
};

export default createTheme(Theme);
