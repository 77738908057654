import React from "react";
import { Box, BoxProps, styled } from "@mui/material";
import { FooterBg, HeadlineBg } from "../../assets/images";

interface BgBoxProps extends BoxProps {
  bgImgType: "headline" | "footer";
}

const BgBox = styled(Box)<BgBoxProps>((props) => ({
  background: `url(${props.bgImgType === "headline" ? HeadlineBg : FooterBg}) no-repeat center center scroll`,
  backgroundSize: "cover",
  textAlign: "center",
  borderBottom: `3px solid ${props.theme.palette.primary.main}`,
}));

const BgBoxBody = styled(Box)({
  padding: "120px 0",
});

type BackgroundBoxProps = {
  children: React.ReactNode;
  bgImgType: "headline" | "footer";
  marginBottom?: number;
};

const BackgroundBox: React.FC<BackgroundBoxProps> = ({ children, bgImgType, marginBottom = 2 }) => {
  return (
    <BgBox bgImgType={bgImgType} mb={marginBottom}>
      <BgBoxBody>
        {children}
      </BgBoxBody>
    </BgBox>
  );
}

export default BackgroundBox;