import React from "react";
import { AuthIncLogoRect } from "../../assets/images";
import { Box, Grid, styled } from "@mui/material";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";

const Navbar = styled("nav")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  width: "100%",
  zIndex: 1000,
  a: {
    color: "white",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}));

const NavContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${theme.spacing(10)})`,
  },
  [theme.breakpoints.down("sm")]: {
    width: `calc(100% - ${theme.spacing(4)})`,
  },
}));

const Img = styled("img")(({ theme }) => ({
  width: 200,
  [theme.breakpoints.down("sm")]: {
    width: 100,
  },
}));

const Navigation: React.FC = () => {
  const routeIsHologramDetails = useRouteMatch(RouteConstants.DETAILS);

  return !routeIsHologramDetails && (
    <Navbar className="no-print">
      <NavContent px={{ xs: 2, sm: 5 }} py={1.5}>
        <Grid container direction="row" sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <Grid item xs={4} md={2} sx={{ display: "flex" }}>
            <Link to={RouteConstants.BASE} style={{ display: "flex" }}>
              <Img src={AuthIncLogoRect} alt="AuthInc rectangle logo" />
            </Link>
          </Grid>
          <Grid item xs={1} sm={4} md={8} />
          <Grid item xs={7} sm={4} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <a href="#contact" style={{ marginRight: 16 }}>
              Contact Us
            </a>
            <Link to={RouteConstants.FAQ}>FAQ</Link>
          </Grid>
        </Grid>
      </NavContent>
    </Navbar>
  );
};

export default Navigation;
