import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navigation from "./components/navigation/Navigation";
import RouteConstants from "./constants/RouteConstants";
import FAQ from "./containers/FAQ";
import Home from "./containers/Home";
import HologramDetails from "./containers/HologramDetails";
import theme from "./theme/theme";
import { Box, styled, ThemeProvider } from "@mui/material";

const Root = styled(Box)({});

const Nav = styled(Box)({});

const Body = styled(Box)({});

const StyledFooter = styled(Box)({});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Root>
          <Nav>
            <Navigation />
          </Nav>
          <Body>
            <React.Suspense fallback={<div />}>
              <Switch>
                <Route path={RouteConstants.BASE} exact component={Home} />
                <Route path={RouteConstants.FAQ} exact component={FAQ} />
                <Route path={RouteConstants.DETAILS} exact component={HologramDetails} />
              </Switch>
            </React.Suspense>
          </Body>
          <StyledFooter>
            <Footer />
          </StyledFooter>
        </Root>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
