import React from "react";
import { Box, Typography } from "@mui/material";
import BackgroundBox from "../common/BackgroundBox";
import { useRouteMatch } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";

const Footer: React.FC = () => {
  const routeIsHologramDetails = useRouteMatch(RouteConstants.DETAILS);
  
  return !routeIsHologramDetails && (
    <Box id="contact" className="no-print">
      <BackgroundBox bgImgType="footer" marginBottom={0}>
        <Box mb={5}>
          <Typography variant="h4" color="white">Interested in Authenticators, Inc?</Typography>
        </Box>
        <Box>
          <Typography variant="h5" color="white">Contact Us</Typography>
          <Typography variant="body1">
            <a href="mailto:info@authenticatorsinc.com">info@authenticatorsinc.com</a>
          </Typography>
        </Box>
      </BackgroundBox>
      <Box sx={{ textAlign: "center", py: 6 }}>
        <Typography variant="body2">© Authenticators Inc. 2016</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
